import React, { useState, useEffect } from 'react';

// --- Mock Data ---
const teamMembers = [
  // Ensure image paths like '/images/team1.png' exist in your public/images folder
  { id: 1, name: 'Pranav Adya', title: 'Co-Founder', role: 'Software and AI Health Monitoring', img: '/images/team1.png', linkedin: 'https://www.linkedin.com/in/pranav-adya-uiuc' },
  { id: 2, name: 'Rohit Seshadri', title: 'Co-Founder', role: 'Design and Hardware', img: '/images/team3.png', linkedin: 'https://www.linkedin.com/in/rohit-seshadri' },
  { id: 3, name: 'Amol Tandon', title: 'Co-Founder', role: 'Electrical and Circuit Design', img: '/images/team2.png', linkedin: 'https://www.linkedin.com/in/amol-tandon-a11971205' },
];

const reasons = [
  { id: 1, title: 'Accurate Health Monitoring', description: 'Real-time insights & alerts. Full day battery life.', icon: 'Activity' },
  { id: 2, title: 'Early Detection', description: 'Predict potential issues before they become serious. Works on dogs 25 lbs+.', icon: 'AlertTriangle' },
  { id: 3, title: 'Smart & Reliable', description: 'Advanced AI keeps your pet safe. 30-second setup.', icon: 'Cpu' },
  { id: 4, title: 'Lightweight & Comfy', description: 'Durable, and designed for comfort.', icon: 'Feather' },
];

// Updated testimonial avatars to dog placeholders
const testimonials = [
    // Ensure image paths like '/images/customer1.png' exist
    { id: 1, name: "Sweety's Mom", role: "First Pre-Order", verified: true, avatar: "/images/snowy.png", quote: "I am so excited for the release of Collie! The early health insights and accurate tracking are a game changer for dog owners." },
    { id: 2, name: "Max's Dad", role: "Interested Customer", verified: true, avatar: "/images/mishty.png", quote: "The idea of Collie is incredible. Knowing I'll get an alert if something's wrong, even when I'm not home, is priceless." },
];

const faqItems = [
    { q: "How does Collie detect health issues?", a: "Collie uses advanced AI to analyze patterns in your dog's vital signs and activity levels, comparing them to their unique baseline to detect subtle changes that may indicate potential health concerns." },
    { q: "How does Collie fit onto your dog?", a: "Collie is special as it can fit onto ANY existing dog collar. Simply attach the metal clips and thats all you have to do! We will soon release our own custom made colorful collars soon!" },
    { q: "What is the battery life?", a: "Collie features an optimized battery system designed to last up to full day on a single charge, depending on usage and cellular signal strength." },
    { q: "What size dogs does Collie fit?", a: "Collie is designed for dogs 25 lbs and up." },
];


// --- Icon Component ---
// (Keep Icon component as previously defined)
const Icon = ({ name, className = "w-6 h-6" }) => {
  const icons = {
    Activity: <path d="M22 12h-4l-3 9L9 3l-3 9H2" />,
    AlertTriangle: <path d="m21.73 18-8-14a2 2 0 0 0-3.46 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z" />,
    Cpu: (<><rect x="4" y="4" width="16" height="16" rx="2" ry="2"/><rect x="9" y="9" width="6" height="6"/><line x1="9" y1="1" x2="9" y2="4"/><line x1="15" y1="1" x2="15" y2="4"/><line x1="9" y1="20" x2="9" y2="23"/><line x1="15" y1="20" x2="15" y2="23"/><line x1="20" y1="9" x2="23" y2="9"/><line x1="20" y1="14" x2="23" y2="14"/><line x1="1" y1="9" x2="4" y2="9"/><line x1="1" y1="14" x2="4" y2="14"/></>),
    Dog: <path d="M10 5.2a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm7.5 0a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm-4.5 9.8 1.1-1.6a.5.5 0 0 1 .8.6L14 16H9.9l-.5-2.3a1 1 0 0 1 .9-1.2l4.4-1c.6-.1 1.2.3 1.3.9l.8 4.3H19v2h-2.1a1 1 0 0 1-1-.8L15 18h-5l-.8 1.2a1 1 0 0 1-1 .8H5v-2h1.4l5.1-7.7a1 1 0 0 1 1.8 0L14 14Z"/>,
    Mail: (<><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></>),
    Linkedin: <><path d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z"></path><rect x="2" y="9" width="4" height="12"></rect><circle cx="4" cy="4" r="2"></circle></>,
    Feather: <path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5zM18 15.5l-5.7-5.7" />,
    CheckCircle: (
      <>
        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
        <polyline points="22 4 12 14.01 9 11.01" />
      </>
    ),
    ChevronDown: <polyline points="6 9 12 15 18 9" />,
    Send: <><line x1="22" y1="2" x2="11" y2="13"></line><polygon points="22 2 15 22 11 13 2 9 22 2"></polygon></> // Send Icon
  };
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className={className} aria-hidden="true">
      {icons[name] || <circle cx="12" cy="12" r="10" />}
    </svg>
  );
};


// --- Navbar Component ---
// (Keep Navbar component as previously defined)
function Navbar({ navigateTo, currentPage }) {
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => setScrolled(window.scrollY > 10);
    setScrolled(window.scrollY > 10);
    if (currentPage === 'home') {
        window.addEventListener('scroll', handleScroll, { passive: true });
    } else {
        window.removeEventListener('scroll', handleScroll);
    }
    return () => window.removeEventListener('scroll', handleScroll);
  }, [currentPage]);

  const isSolidBlueNav = ['product', 'about', 'contact'].includes(currentPage);
  const navBackgroundClass = isSolidBlueNav ? 'bg-gradient-to-r from-blue-600 to-indigo-700 shadow-lg' : scrolled ? 'bg-white/90 shadow-lg backdrop-blur-lg' : 'bg-transparent';
  const isDarkBackground = isSolidBlueNav || (currentPage === 'home' && !scrolled);
  const textColorClass = isDarkBackground ? 'text-white' : 'text-gray-800';
  const hoverTextColorClass = isDarkBackground ? 'hover:text-gray-200' : 'hover:text-blue-700';
  const buttonTextColorClass = isDarkBackground ? 'text-gray-100' : 'text-gray-700';
  const buttonHoverTextColorClass = isDarkBackground ? 'hover:text-white' : 'hover:text-blue-600';
  const focusRingClass = isDarkBackground ? 'focus:ring-white' : 'focus:ring-blue-500';
  const logoDropShadow = isDarkBackground ? 'drop-shadow-[0_1px_1px_rgba(255,255,255,0.5)]' : 'drop-shadow-[0_1px_1px_rgba(0,0,0,0.2)]';

  const handleNavigate = (page, anchor = null) => {
    navigateTo(page, anchor);
    setMobileMenuOpen(false);
  };

  return (
    <header className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ease-in-out ${navBackgroundClass}`}>
      <nav className="container mx-auto px-4 sm:px-6 lg:px-8 flex items-center justify-between h-16">
        {/* Logo */}
        <button onClick={() => handleNavigate('home')} className={`flex items-center gap-2 text-xl transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-md group ${focusRingClass}`}>
          {/* ** ACTION REQUIRED: Ensure '/images/CollieLogo1.png' exists in your public/images folder ** */}
          <img src="/images/CollieLogo1.png" alt="Collie Logo" className={`h-8 w-auto transition-all duration-300 ease-in-out ${logoDropShadow}`} onError={(e) => { e.target.onerror = null; e.target.src='https://placehold.co/120x40/CCCCCC/FFFFFF?text=LogoError'; }}/>
          <span className={`font-bold transition-colors duration-300 ease-in-out ${textColorClass} ${hoverTextColorClass}`}>Collie</span>
        </button>

        {/* Desktop Nav */}
        <div className="hidden md:flex items-center space-x-6">
          <button onClick={() => handleNavigate('home')} className={`text-sm font-medium px-3 py-2 rounded-md transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusRingClass} ${buttonTextColorClass} ${buttonHoverTextColorClass}`}>Home</button>
          <button onClick={() => handleNavigate('product')} className={`text-sm font-medium px-3 py-2 rounded-md transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusRingClass} ${buttonTextColorClass} ${buttonHoverTextColorClass}`}>Product</button>
          <button onClick={() => handleNavigate('about')} className={`text-sm font-medium px-3 py-2 rounded-md transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusRingClass} ${buttonTextColorClass} ${buttonHoverTextColorClass}`}>About</button>
          <button onClick={() => handleNavigate('contact')} className={`text-sm font-medium px-3 py-2 rounded-md transition-colors duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 ${focusRingClass} ${buttonTextColorClass} ${buttonHoverTextColorClass}`}>Contact</button>
        </div>

        {/* Desktop CTA Button */}
        <button onClick={() => handleNavigate('home', '#subscribe')} className={`hidden md:inline-block text-sm font-medium px-6 py-2.5 rounded-xl transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 transform hover:-translate-y-0.5 hover:scale-105 ${isDarkBackground ? 'bg-white text-blue-600 hover:bg-gray-100 focus:ring-white' : 'bg-blue-600 hover:bg-blue-700 text-white focus:ring-blue-500'}`}>
          Get Notified
        </button>

        {/* Mobile Menu Button */}
        <div className="md:hidden flex items-center">
          <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)} className={`p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-inset ${ isDarkBackground ? 'text-gray-100 hover:bg-white/20 focus:ring-white' : 'text-gray-700 hover:bg-gray-100 focus:ring-blue-500'}`} aria-label="Toggle menu">
            {mobileMenuOpen ? ( <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" /></svg> ) : ( <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" /></svg> )}
          </button>
        </div>
      </nav>

      {/* Mobile Menu Panel */}
      {mobileMenuOpen && (
         <div className={`md:hidden absolute top-16 left-0 right-0 shadow-xl transition-all duration-300 ease-in-out ${ isDarkBackground ? 'bg-gradient-to-b from-blue-600 to-indigo-700' : 'bg-white'}`}>
          <div className="px-4 pt-2 pb-4 space-y-2">
            <button onClick={() => handleNavigate('home')} className={`block w-full text-left px-3 py-2 rounded-md text-base font-medium transition-colors duration-300 ease-in-out ${isDarkBackground ? 'text-white hover:bg-white/20' : 'text-gray-700 hover:bg-gray-100 hover:text-blue-600'}`}>Home</button>
            <button onClick={() => handleNavigate('product')} className={`block w-full text-left px-3 py-2 rounded-md text-base font-medium transition-colors duration-300 ease-in-out ${isDarkBackground ? 'text-white hover:bg-white/20' : 'text-gray-700 hover:bg-gray-100 hover:text-blue-600'}`}>Product</button>
            <button onClick={() => handleNavigate('about')} className={`block w-full text-left px-3 py-2 rounded-md text-base font-medium transition-colors duration-300 ease-in-out ${isDarkBackground ? 'text-white hover:bg-white/20' : 'text-gray-700 hover:bg-gray-100 hover:text-blue-600'}`}>About</button>
            <button onClick={() => handleNavigate('contact')} className={`block w-full text-left px-3 py-2 rounded-md text-base font-medium transition-colors duration-300 ease-in-out ${isDarkBackground ? 'text-white hover:bg-white/20' : 'text-gray-700 hover:bg-gray-100 hover:text-blue-600'}`}>Contact</button>
             <button onClick={() => handleNavigate('home', '#subscribe')} className={`block w-full text-center mt-3 px-6 py-2.5 rounded-xl text-base font-medium transition-colors duration-300 ease-in-out shadow-md ${ isDarkBackground ? 'bg-white text-blue-600 hover:bg-gray-100' : 'bg-blue-600 hover:bg-blue-700 text-white'}`}>
              Get Notified
            </button>
          </div>
        </div>
      )}
    </header>
  );
}


// --- Subscriber Form Component ---
// (Keep SubscribeForm component as previously defined)
function SubscribeForm() {
  const [email, setEmail] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [status, setStatus] = useState("idle");

  const googleAppsScriptUrl = 'YOUR_DEPLOYED_GOOGLE_APPS_SCRIPT_URL_HERE'; // <-- PASTE YOUR URL HERE

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResponseMessage("");
    setStatus("submitting");

    try {
      const formData = new FormData();
      formData.append("email", email);

      // Replace with your actual Google Apps Script URL
      const res = await fetch("https://script.google.com/macros/s/AKfycbx8avnNJds9aAt6nNk1U6rMkA2ZVO_2W7IZc2SN-7-TfyNcHYM7R0AolYOACShy4xnGNA/exec", {
        method: "POST",
        body: formData,
      });

      // Basic check if response is OK, Google Scripts often don't return standard JSON APIs
      if (res.ok) {
        // Attempt to parse JSON, but handle cases where it might not be JSON
        try {
          const data = await res.json();
          if (data.status === "success") {
             setResponseMessage("Thanks for subscribing! We'll keep you updated.");
             setStatus("success");
             setEmail(""); // Clear the input on success
          } else {
             setResponseMessage("Subscription failed. Please try again.");
             setStatus("error");
          }
        } catch (jsonError) {
            // If response is not JSON but was OK (status 200), assume success
            console.log("Response was OK but not valid JSON, assuming success based on Google Script behavior.");
            setResponseMessage("Thanks for subscribing! We'll keep you updated.");
            setStatus("success");
            setEmail(""); // Clear the input on success
        }
      } else {
         setResponseMessage("Something went wrong. Please try again later.");
         setStatus("error");
      }
    } catch (error) {
      console.error("Subscription error:", error);
      setResponseMessage("An error occurred. Please try again.");
      setStatus("error");
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row justify-center gap-3 sm:gap-4 mt-8 max-w-lg mx-auto relative">
        <label htmlFor="email-subscribe" className="sr-only">Email address</label>
        <div className="relative flex-grow">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Icon name="Mail" className="w-5 h-5 text-gray-400" />
            </div>
            <input id="email-subscribe" name="email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email address" required disabled={status === 'submitting'} className="w-full pl-10 pr-5 py-3 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent shadow-sm transition-shadow duration-200 disabled:opacity-50"/>
        </div>
        <button type="submit" disabled={status === 'submitting'} className={`w-full sm:w-auto bg-blue-600 hover:bg-blue-700 text-white font-semibold px-6 sm:px-8 py-3 rounded-lg transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-60 disabled:cursor-not-allowed transform hover:-translate-y-0.5 disabled:transform-none`}>
          {status === 'submitting' ? 'Subscribing...' : 'Subscribe'}
        </button>
      </form>
      <div className="mt-4 min-h-[2.5rem] text-center">
        {responseMessage && (
          <p className={`text-sm mb-1 ${status === 'success' ? 'text-green-700' : 'text-red-600'}`}>{responseMessage}</p>
        )}
        {(status === 'idle' || status === 'error') && (
             <p className="text-xs text-gray-500">No spam. Only occasional updates. 🐾</p>
        )}
        {googleAppsScriptUrl === 'YOUR_DEPLOYED_GOOGLE_APPS_SCRIPT_URL_HERE' && status !== 'submitting' && (
            <p className="text-xs text-yellow-600 mt-1"></p>
        )}
      </div>
    </>
  );
}


// --- How It Works Section ---
// Updated 3rd circle color
function HowItWorks() {
    return (
        <section id="how-it-works" className="py-20 sm:py-28 bg-white">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center">
                <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-16">How Collie Works</h2>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 lg:gap-12 max-w-4xl mx-auto">
                    {/* Step 1 */}
                    <div className="text-center animate-fade-in-up">
                        <div className="inline-flex items-center justify-center w-20 h-20 mb-6 rounded-full bg-blue-100 text-blue-600 text-2xl font-bold">1</div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-3">Monitor & Analyze</h3>
                        <p className="text-gray-600 leading-relaxed">Along with all the features a traditional activity + device would provide, Collie continuously monitors vital signs and activity, using AI to establish your dog's unique baseline.</p>
                    </div>
                    {/* Step 2 */}
                    <div className="text-center animate-fade-in-up delay-100">
                        <div className="inline-flex items-center justify-center w-20 h-20 mb-6 rounded-full bg-indigo-100 text-indigo-600 text-2xl font-bold">2</div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-3">Detect Deviations</h3>
                        <p className="text-gray-600 leading-relaxed">Our AI detects subtle changes or patterns that deviate from the norm, indicating potential health shifts. Those subtle changes can save your pets life.</p>
                    </div>
                    {/* Step 3 - Changed color from purple to teal */}
                    <div className="text-center animate-fade-in-up delay-200">
                        <div className="inline-flex items-center justify-center w-20 h-20 mb-6 rounded-full bg-purple-100 text-purple-600 text-2xl font-bold">3</div>
                        <h3 className="text-xl font-semibold text-gray-800 mb-3">Alert & Inform</h3>
                        <p className="text-gray-600 leading-relaxed">Receive timely, actionable alerts on your phone, empowering early vet consultation if needed. You have the power to make quick decisions.</p>
                    </div>
                </div>
                 <div className="mt-16 text-gray-400 text-4xl">🐾</div>
            </div>
        </section>
    );
}


// --- FAQ Item Component ---
// (Keep FaqItem component as previously defined)
function FaqItem({ question, answer }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="border-b border-gray-200 py-5">
      <button className="flex justify-between items-center w-full text-left text-lg font-medium text-gray-700 hover:text-blue-600 focus:outline-none" onClick={() => setIsOpen(!isOpen)} aria-expanded={isOpen}>
        <span>{question}</span>
        <Icon name="ChevronDown" className={`w-5 h-5 transition-transform duration-300 ${isOpen ? 'transform rotate-180' : ''}`} />
      </button>
      <div className={`overflow-hidden transition-all duration-500 ease-in-out ${isOpen ? 'max-h-96 mt-3' : 'max-h-0'}`}>
           <p className="text-gray-600 leading-relaxed">{answer}</p>
      </div>
    </div>
  );
}


// --- FAQ Section ---
// (Keep FaqSection component as previously defined)
function FaqSection() {
    return (
        <section id="faq" className="py-20 sm:py-28 bg-gray-50">
            <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-3xl">
                <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-12 text-center">
                    Frequently Asked Questions
                </h2>
                <div className="space-y-4">
                    {faqItems.map((item, index) => (
                        <FaqItem key={index} question={item.q} answer={item.a} />
                    ))}
                </div>
            </div>
        </section>
    );
}


// --- Home Page Component ---
// Updated testimonial section
function Home({ navigateTo, scrollTarget, clearScrollTarget }) {
    useEffect(() => {
        if (scrollTarget) {
            const element = document.querySelector(scrollTarget);
            if (element) {
                setTimeout(() => element.scrollIntoView({ behavior: 'smooth', block: 'start' }), 100);
            } else {
                console.warn(`Target element "${scrollTarget}" not found for scrolling.`);
            }
            clearScrollTarget();
        }
    }, [scrollTarget, clearScrollTarget]);

  return (
    <>
      {/* Hero Section */}
      <section className="relative flex items-center pt-16 pb-16 sm:pt-20 sm:pb-20 lg:pt-24 lg:pb-24 overflow-hidden bg-gradient-to-br from-blue-600 via-indigo-700 to-purple-800 rounded-b-3xl md:rounded-b-[60px] shadow-xl min-h-[calc(100vh-4rem)]">
         <div className="absolute inset-0 bg-gradient-to-tr from-black/15 to-transparent opacity-40" aria-hidden="true"></div>
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-10 flex flex-col lg:flex-row items-center gap-10 lg:gap-12">
          <div className="lg:w-5/12 text-center lg:text-left">
            <h1 className="text-4xl sm:text-5xl lg:text-5xl xl:text-6xl font-extrabold text-white leading-tight tracking-tight mb-5 animate-fade-in-down">
              Collie: The AI-Powered Smart Dog Collar
            </h1>
            <p className="text-xl sm:text-2xl text-indigo-100 mb-10 animate-fade-in-up delay-100 leading-relaxed">
              Early health insights for your dog. Save time, money, and lives with Collie.
            </p>
            <button onClick={() => navigateTo('home', '#subscribe')} className="inline-block bg-white text-blue-600 font-semibold px-8 py-3.5 rounded-xl shadow-lg hover:bg-gray-100 transition-all duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white transform hover:scale-105 hover:-translate-y-0.5 animate-fade-in-up delay-200">
              Get Notified
            </button>
          </div>
          <div className="lg:w-7/12 mt-10 lg:mt-0 animate-fade-in-scale-up delay-300">
            {/* ** ACTION REQUIRED: Ensure '/images/collarOnDog.png' exists in your public/images folder ** */}
            <img src="/images/collarOnDog.png" alt="Happy dog wearing the Collie smart collar outdoors" className="rounded-2xl shadow-2xl mx-auto transform hover:scale-105 transition-transform duration-500 ease-out max-h-[70vh]" onError={(e) => { e.target.onerror = null; e.target.src='https://placehold.co/800x600/E2E8F0/4A5568?text=Collie+Collar'; }} loading="eager"/>
          </div>
        </div>
      </section>

      {/* How It Works Section */}
      <HowItWorks />

      {/* Why Choose Collie Section */}
      <section id="features" className="py-20 sm:py-28 bg-gray-50 relative overflow-hidden">
          <div className="absolute inset-0 opacity-[0.03] bg-paw-pattern" aria-hidden="true"></div>
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center relative z-10">
            <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-16">
              Why Choose Collie?
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-10">
              {reasons.map((reason, index) => (
                <div key={reason.id} className={`bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out text-center transform hover:-translate-y-1.5 animate-fade-in-up delay-${index * 100} group`}>
                  <div className="inline-flex items-center justify-center w-16 h-16 mb-6 rounded-full bg-blue-100 text-blue-600 transform group-hover:scale-110 transition-transform duration-300 ease-in-out">
                    <Icon name={reason.icon} className="w-8 h-8" />
                  </div>
                  <h3 className="text-xl font-semibold text-gray-800 mb-3">{reason.title}</h3>
                  <p className="text-gray-600 leading-relaxed">{reason.description}</p>
                </div>
              ))}
            </div>
          </div>
      </section>

      {/* Customer Quote Section - Removed quote SVG, updated avatars */}
      <section id="testimonial" className="py-20 sm:py-28 bg-white">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 max-w-4xl text-center">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-16">
            What Our Users Say
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-10 lg:gap-16">
            {testimonials.map((testimonial, index) => (
              <div key={testimonial.id} className={`text-center md:text-left flex flex-col md:flex-row items-center gap-6 animate-fade-in-up delay-${index * 150}`}>
                  {/* ** ACTION REQUIRED: Ensure testimonial avatar paths like '/images/customer1.png' exist ** */}
                  <img src={testimonial.avatar} alt={`Avatar of ${testimonial.name}`} className="w-20 h-20 rounded-full shadow-md object-cover flex-shrink-0" onError={(e) => { e.target.onerror = null; e.target.src='https://placehold.co/80x80/A0AEC0/FFFFFF?text=🐾'; }} loading="lazy"/>
                  {/* Removed the large quote SVG background */}
                  <div className="relative">
                      <blockquote className="text-lg font-medium text-gray-700 italic mb-4 relative z-10 leading-relaxed">
                        "{testimonial.quote}"
                      </blockquote>
                      <p className="text-md font-semibold text-gray-800">{testimonial.name}, {testimonial.role}</p>
                      {testimonial.verified && (
                          <span className="inline-flex items-center gap-1 text-xs text-green-700 font-medium mt-1">
                              <Icon name="CheckCircle" className="w-3 h-3" /> Verified Customer
                          </span>
                      )}
                  </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Subscriber Section */}
      <section id="subscribe" className="py-20 sm:py-28 bg-gradient-to-br from-blue-50 to-indigo-100">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center max-w-2xl">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-4">
            Stay Ahead of the Pack
          </h2>
          <p className="text-lg text-gray-600 mb-8">
            Get exclusive updates, launch notifications, and special offers delivered straight to your inbox.
          </p>
          <SubscribeForm />
        </div>
      </section>

      {/* Get in Touch Summary Section */}
      <section id="contact-summary" className="py-20 sm:py-28 bg-white text-center">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-4">
            Get in Touch
          </h2>
          <p className="text-lg text-gray-600 mb-10 max-w-xl mx-auto leading-relaxed">
            Have questions, feedback, or just want to say hi? We'd love to hear from you! Reach out and let's chat about keeping your dog healthy and happy.
          </p>
          <button onClick={() => navigateTo('contact')} className="inline-block bg-gray-800 hover:bg-gray-900 text-white font-semibold px-8 py-3.5 rounded-xl transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transform hover:-translate-y-0.5">
            Contact Us
          </button>
        </div>
      </section>

      {/* FAQ Section */}
      <FaqSection />
    </>
  );
}


// --- Product Page Component ---
// (Keep Product component as previously defined)
function Product({ navigateTo }) {
  return (
    <main className="relative pt-28 pb-16 sm:pt-36 sm:pb-24 bg-gradient-to-br from-gray-100 to-blue-100 min-h-screen flex items-center justify-center">
      <div className="relative z-10 container mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 items-start">
        <div className="bg-white/80 backdrop-blur-sm p-8 rounded-2xl shadow-xl animate-fade-in-left order-2 md:order-1 flex flex-col h-full">
           <h2 className="text-3xl font-bold text-gray-800 mb-4">Beyond Tracking: Predictive Health</h2>
           <p className="text-gray-700 mb-4 leading-relaxed">
             Introducing Collie - the revolutionary AI-powered smart dog collar designed to empower you with real-time, actionable insights into your dog's well-being. Collie's advanced AI analyzes subtle biometric signals to predict potential health issues before they become serious problems.
           </p>
           <p className="text-gray-700 mb-6 leading-relaxed">
             This proactive approach helps you intervene early, potentially saving significant time, money on vet bills, and most importantly, giving you the best chance to safeguard your beloved companion's life.
           </p>
           <h3 className="text-2xl font-semibold text-gray-800 mb-3">Peace of Mind Alerts</h3>
           <ul className="list-disc list-inside text-gray-700 space-y-2 mb-6">
             <li><span className="font-semibold">Emergency Detection:</span> Alerts for potential drowning or suffocation patterns.</li>
             <li><span className="font-semibold">Geofence Security:</span> Instant breach notifications.</li>
             <li><span className="font-semibold">Subtle Health Shifts:</span> Deviation alerts for timely vet visits.</li>
             <li><span className="font-semibold">Activity Tracking:</span> Monitor daily routine, activity, and sleep.</li>
           </ul>
           <div className="mt-auto pt-6 border-t border-gray-200">
               <h3 className="text-xl font-semibold text-gray-800 mb-3">Key Specs</h3>
               <ul className="text-sm text-gray-600 space-y-1">
                   <li><span className="font-medium">Battery Life:</span> Up to a full day per charge</li>
                   <li><span className="font-medium">Compatibility:</span> IOS App</li>
                   <li><span className="font-medium">Weight:</span> Approx. 35 grams</li>
                   <li><span className="font-medium">Sizing:</span> Fits dogs 25 lbs and up</li>
               </ul>
               <p className="text-xs text-gray-500 mt-4">Collie uses secure encrypted data — your pet’s health stays private.</p>
           </div>
        </div>
        <div className="bg-white/80 backdrop-blur-sm p-8 sm:p-12 rounded-2xl shadow-xl max-w-lg mx-auto animate-fade-in-right order-1 md:order-2 w-full">
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-8 text-center">Collie Smart Collar</h1>
          {/* ** ACTION REQUIRED: Ensure '/images/collar.png' exists in your public/images folder ** */}
          <img src="/images/collar.png" alt="Collie Smart Collar product image" className="rounded-lg mb-8 mx-auto shadow-md max-w-[300px] w-full" onError={(e) => { e.target.onerror = null; e.target.src='https://placehold.co/300x225/E2E8F0/4A5568?text=Collar+Image'; }} loading="lazy"/>
          <div className="bg-red-100 border border-red-300 text-red-800 px-6 py-4 rounded-lg mb-6 text-center">
            <p className="text-2xl font-bold">Currently Sold Out</p>
            <p className="mt-2">We're working hard to restock! Sign up for notifications.</p>
          </div>
          <div className="text-center">
              <button onClick={() => navigateTo('home', '#subscribe')} className="inline-block bg-blue-600 hover:bg-blue-700 text-white font-medium px-8 py-3 rounded-xl transition-all duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transform hover:-translate-y-0.5">
                Get Notified for Restock
              </button>
          </div>
           <div className="mt-8 text-center">
               <p className="text-sm text-gray-500">(Future: Add comparison table / infographic here)</p>
           </div>
        </div>
      </div>
    </main>
  );
}


// --- About Page Component ---
// Made entire card clickable
function About() {
  return (
    <main className="pt-28 pb-16 sm:pt-36 sm:pb-24 bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen flex flex-col items-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center w-full">
        <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-16">
          Meet the Team Behind Collie
        </h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8 lg:gap-12 max-w-4xl mx-auto">
          {teamMembers.map((member, index) => (
            // Wrap the entire card content in an anchor tag
            <a
              key={member.id}
              href={member.linkedin} // Link destination
              target="_blank" // Open in new tab
              rel="noopener noreferrer" // Security measure
              className={`block bg-white p-8 rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 ease-in-out text-center transform hover:-translate-y-1.5 animate-fade-in-up delay-${index * 100} group relative focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500`} // Added block, focus styles
              aria-label={`View ${member.name}'s LinkedIn Profile`} // Accessibility
            >
              {/* ** ACTION REQUIRED: Ensure team image paths like '/images/team1.png' exist ** */}
              <img src={member.img} alt={`Profile picture of ${member.name}`} className="w-28 h-28 rounded-full mx-auto mb-5 shadow-md object-cover border-4 border-white ring-1 ring-gray-200" onError={(e) => { e.target.onerror = null; e.target.src='https://placehold.co/112x112/E2E8F0/4A5568?text=Photo'; }} loading="lazy"/>
              <h3 className="text-xl font-semibold text-gray-800">{member.name}</h3>
              {/* Use span for title/role as link styling is handled by parent 'a' */}
              <span className="block text-blue-600 font-medium mb-1">{member.title}</span>
              <span className="block text-xs text-gray-500 mb-3">{member.role}</span>
              {/* LinkedIn Icon (visual cue, link handled by parent 'a') */}
              {member.linkedin && member.linkedin !== '#' && (
                  <div className="absolute top-4 right-4 text-gray-400 group-hover:text-blue-700 transition-all duration-200 ease-in-out opacity-0 group-hover:opacity-100 scale-90 group-hover:scale-100">
                    <Icon name="Linkedin" className="w-5 h-5" />
                  </div>
              )}
            </a>
          ))}
        </div>
      </div>
    </main>
  );
}


// --- Contact Page Component ---
// Added state and fetch logic for the contact form
function Contact() {
  const linkedInProfiles = teamMembers.filter(m => m.title !== 'Chief Morale Officer').map(member => ({ name: member.name, url: member.linkedin || '#' }));

  // State for contact form
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [formStatus, setFormStatus] = useState({ submitting: false, submitted: false, error: null });

  const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleContactSubmit = async (e) => {
      e.preventDefault();
      setFormStatus({ submitting: true, submitted: false, error: null });

      try {
          const response = await fetch('/.netlify/functions/contact', { // Endpoint for Netlify function
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(formData),
          });

          if (response.ok) {
              setFormStatus({ submitting: false, submitted: true, error: null });
              setFormData({ name: '', email: '', message: '' }); // Clear form
          } else {
              const errorData = await response.json().catch(() => ({ message: 'Submission failed. Please try again.' }));
              setFormStatus({ submitting: false, submitted: false, error: errorData.message || 'Submission failed.' });
          }
      } catch (error) {
          console.error('Contact form submission error:', error);
          setFormStatus({ submitting: false, submitted: false, error: 'An network error occurred. Please try again.' });
      }
  };

  return (
    <main className="pt-28 pb-16 sm:pt-36 sm:pb-24 bg-gradient-to-br from-blue-50 to-indigo-100 min-h-screen flex items-center justify-center">
      <div className="container mx-auto px-4 sm:px-6 lg:px-8 grid grid-cols-1 md:grid-cols-2 gap-10 items-start">
        {/* Left Column: Contact Info */}
        <div className="bg-white p-8 sm:p-10 rounded-2xl shadow-xl animate-fade-in-left">
          <h1 className="text-3xl sm:text-4xl font-bold text-gray-800 mb-8 text-center md:text-left">Contact Us</h1>
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-700 mb-3">Email Us</h2>
            <a href="mailto:thecollieteam@gmail.com" className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-800 hover:underline text-lg transition-colors duration-200">
              <Icon name="Mail" className="w-5 h-5" /> thecollieteam@gmail.com
            </a>
            <p className="text-gray-500 text-sm mt-2">We typically respond within 24 hours.</p>
          </div>
          <div className="mb-8">
            <h2 className="text-2xl font-semibold text-gray-700 mb-4">Connect on LinkedIn</h2>
            <div className="space-y-3">
              {linkedInProfiles.map(profile => (
                <a key={profile.name} href={profile.url} target="_blank" rel="noopener noreferrer" className="inline-flex items-center gap-2 text-blue-600 hover:text-blue-800 hover:underline transition-colors duration-200 mr-4">
                  <Icon name="Linkedin" className="w-5 h-5" /> {profile.name}
                </a>
              ))}
            </div>
          </div>
           <div>
               <h2 className="text-2xl font-semibold text-gray-700 mb-2">Location</h2>
               <p className="text-gray-600">Champaign, IL</p>
           </div>
        </div>

        {/* Right Column: Contact Form - Now functional */}
        <div className="bg-white p-8 sm:p-10 rounded-2xl shadow-xl animate-fade-in-right h-full flex flex-col justify-center">
             <h2 className="text-2xl font-semibold text-gray-700 mb-4 text-center">Send Us a Message</h2>
             {/* Display success/error messages */}
             <div className="mb-4 min-h-[2rem]">
                 {formStatus.submitted && (
                     <p className="text-center text-green-600 bg-green-50 p-3 rounded-md">Message sent successfully! We'll get back to you soon.</p>
                 )}
                 {formStatus.error && (
                     <p className="text-center text-red-600 bg-red-50 p-3 rounded-md">Error: {formStatus.error}</p>
                 )}
             </div>
             {/* Hide form on successful submission */}
             {!formStatus.submitted && (
                 <form onSubmit={handleContactSubmit} className="space-y-4">
                     <div>
                         <label htmlFor="contact-name" className="sr-only">Name</label>
                         <input
                             type="text"
                             id="contact-name"
                             name="name" // Add name attribute
                             value={formData.name}
                             onChange={handleInputChange}
                             placeholder="Your Name"
                             required
                             className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                             disabled={formStatus.submitting}
                         />
                     </div>
                     <div>
                         <label htmlFor="contact-email" className="sr-only">Email</label>
                         <input
                            type="email"
                            id="contact-email"
                            name="email" // Add name attribute
                            value={formData.email}
                            onChange={handleInputChange}
                            placeholder="Your Email"
                            required
                            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            disabled={formStatus.submitting}
                         />
                     </div>
                     <div>
                         <label htmlFor="contact-message" className="sr-only">Message</label>
                         <textarea
                            id="contact-message"
                            name="message" // Add name attribute
                            rows="4"
                            value={formData.message}
                            onChange={handleInputChange}
                            placeholder="Your Message"
                            required
                            className="w-full px-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500"
                            disabled={formStatus.submitting}
                         ></textarea>
                     </div>
                     <div className="text-center">
                         <button
                            type="submit"
                            className="inline-flex items-center justify-center bg-blue-600 hover:bg-blue-700 text-white font-semibold px-8 py-3 rounded-xl transition-colors duration-300 ease-in-out shadow-md hover:shadow-lg focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50"
                            disabled={formStatus.submitting}
                         >
                             {formStatus.submitting ? (
                                <>
                                 <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg>
                                Sending...
                                </>
                             ) : (
                                <> Send Message <Icon name="Send" className="w-4 h-4 ml-2"/> </>
                             )}
                         </button>
                     </div>
                 </form>
             )}
        </div>
      </div>
    </main>
  );
}


// --- Main App Component ---
function App() {
  const [currentPage, setCurrentPage] = useState('home');
  const [scrollTarget, setScrollTarget] = useState(null);

  const navigateTo = (page, anchor = null) => {
    if (page === currentPage && anchor) {
        const element = document.querySelector(anchor);
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setScrollTarget(null);
    } else {
        setCurrentPage(page);
        setScrollTarget(anchor);
        if (!anchor) window.scrollTo({ top: 0, behavior: 'instant' });
    }
  };

  const clearScrollTarget = () => setScrollTarget(null);

  const renderPage = () => {
    switch (currentPage) {
      case 'product': return <Product navigateTo={navigateTo} />;
      case 'about': return <About />;
      case 'contact': return <Contact />;
      case 'home': default: return <Home navigateTo={navigateTo} scrollTarget={scrollTarget} clearScrollTarget={clearScrollTarget} />;
    }
  };

  return (
    <>
      <Navbar navigateTo={navigateTo} currentPage={currentPage} />
      <main key={currentPage}>
          {renderPage()}
      </main>
      <footer className="bg-gray-800 text-gray-400 py-10">
        <div className="container mx-auto px-4 sm:px-6 lg:px-8 text-center text-sm">
          © {new Date().getFullYear()} Collie. All rights reserved.
        </div>
      </footer>
      {/* CSS rules should be in src/index.css */}
    </>
  );
}

export default App;
